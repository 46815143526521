import Section from '../../../../components/Section';
import Step from '../../../../components/Steps/Step';
import styles from '../../../../components/Steps/Steps.module.scss';

import iconUser from './icons/icon-user.svg';
import iconMath from './icons/icon-math.svg';
import iconFace from './icons/icon-face.png';
import iconTask from './icons/icon-task.svg';
import iconVerified from './icons/icon-verified.svg';
import iconCriminal from './icons/icon-cybercriminals.svg';

const Steps = () => (
  <Section id="Steps" className={styles.Steps}>
    <Step idx={1} image={iconUser}>
      <p>
        We use our open-source JavaScript to gather the user's cursor movement
        and typing data, as well as other metadata, to create a comprehensive
        biometric profile.
      </p>
    </Step>

    <Step idx={2} image={iconMath} inverse>
      <p>
        The anonymized data is securely sent to our servers, where we analyze
        the dynamic characteristics of cursor movements and typing patterns.
      </p>
    </Step>

    <Step idx={3} image={iconFace}>
      <p>
        Once enough data is collected, our deep-tech adaptive algorithms build
        unique biometric profiles.
      </p>
    </Step>

    <Step idx={4} image={iconTask} inverse>
      <p>
        The system compares the user's real-time behavior patterns with their
        biometric profile and determines an Identity Score, updated several
        times a minute.
      </p>
    </Step>

    <Step idx={5} image={iconVerified}>
      <p>
        When a user's Identity Score drops below the threshold, Graboxy flags
        the session as suspicious, and an "Unauthorized User" alert is sent out.
      </p>
    </Step>

    <Step idx={6} image={iconCriminal} inverse showSeparator={false}>
      <p>
        Flagged users can be locked out or re-verified using different
        multi-factor authentication methods.
      </p>
    </Step>
  </Section>
);

export default Steps;
